.App {
  text-align: center;
  align-items: center;
}

.App-header {
  font-size: 25pt;
  font-weight: bolder ;
}

.test-box {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 365px;
  align-items: flex-start;
}

.test-container {
  display: flex;
  flex-wrap: wrap;
  font-size: 9pt;
  margin: 3px;
}

.score-style {
  font-size: 15pt;
  font-weight: bold;
  margin-top: 30px;
}

.response-style {
  font-size: 15px;
  margin-top: 5px;
  width: 250px;
}

.result-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.item-row {
  display: flex;
  align-items: row;
}

.test-description {
  margin-bottom: 30px;
  width: 90%;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.margin-wrapper {
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}